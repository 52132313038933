<template>
  <div>
      <div v-if="categories.length && services.length" class="d-flex flex-wrap justify-space-evenly my-5">
        <v-card
          v-for="category in categories"
          :key="category._id"
          max-width="344"
          class="mx-auto mb20 d-flex flex-column align-content-start"
          outlined
          elevation="3"
          @click="clickHandler(category)"
          rounded="xl"
        >
          <ImageForCardWithTitle
            :image="category.image"
            :image-url="category.imageUrl"
            :title="category.title"
            main-theme-color="teal"
          />

          <v-card-subtitle class="flex-grow-1 teal--text text--lighten-1">
            {{ category.description }}
          </v-card-subtitle>

          <v-card-subtitle
            v-if="category.services.length === 1"
            class="teal--text text--lighten-1 font-weight-bold"
          >
            Цена: {{ category.services[0].price }} руб
          </v-card-subtitle>
        </v-card>
      </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import ImageForCardWithTitle from "@/components/ImageForCardWithTitle";

  export default {
    name: "ChooseCategoryMaster",
    components: {ImageForCardWithTitle},

    data: () => ({
      categories: [],
      services: []
    }),
    computed: {
      ...mapGetters(['ownerId'])
    },
    methods: {
      async loadCategoryList() {
        const json = await this.authFetch('/category/load-category-list-for-user', {})
        if (json.status === 200) {
          this.categories = json.categories.map(category => {
            category.services = []
            return category
          })
        }
      },

      async loadServiceListsForCategories() {
        const json = await this.authFetch(
          `/service/load-service-list-for-user`,
          {},
          false
        )

        if (json.status === 200) {
          this.services = json.services
          this.categories.forEach(category => {
            for (let service of this.services) {
              if (service.category === category._id) {
                category.services.push(service)
              }
            }
          })
        }
      },

      clickHandler(category) {
        this.$store.commit('setCategory', category)
        if (category.services.length === 1) {
          this.$store.commit("setServices", category.services)
          this.$store.commit("setTotal", category.services[0].price)
          this.$router.push("/introduce-master")
        } else {
          this.$router.push("/choose-service-master")
        }
      }
    },

    async mounted() {
      if (!this.$store.getters.date || !this.$store.getters.time) {
        await this.$router.push('/calendar')
      }
      await this.loadCategoryList()
      await this.loadServiceListsForCategories()
    },
  }
</script>

<style scoped>

</style>
